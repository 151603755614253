import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

const FormSuccess = () => {
  return (
    <Layout>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 'max-content',
        margin: 'auto',
        marginTop: '2em'
      }}>
        <h1>Thank you for reaching out!</h1>
        <p>I'll be in contact shortly.</p>
        <Link to="/" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
          <button>Return</button>
        </Link>
      </div>
    </Layout>
  )
}

export default FormSuccess