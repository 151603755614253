import React from 'react'
import NavBar from './Navbar'
import '../styles/global.css'

// Wrapper component for navbar, pages, and footer.
// All pages must be wrapped with Layout.
const Layout = ({ children }) => {
  return (
    <div className="wrapper">
      <NavBar />
      <div className="content">
        {children}
      </div>
      <footer>
        <p>Smart & Safe Driving School 2010 - 2022</p>
      </footer>
    </div>
  )
}

export default Layout